import React, { useEffect, useState } from 'react';
import { Layout, Card, Tag, Space, Button, Typography, Form, Spin, Tooltip, Pagination } from '@douyinfe/semi-ui';
import { API, showError, showSuccess, timestamp2string } from '../../helpers';
import { CHANNEL_OPTIONS, ITEMS_PER_PAGE } from '../../constants';
import { IconRefresh } from '@douyinfe/semi-icons';
import './style.css';

const { Meta } = Card;

const ChannelDashBoard = () => {
    const [channels, setChannels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [searching, setSearching] = useState(false);
    const [testingChannels, setTestingChannels] = useState(new Set());
    const [activePage, setActivePage] = useState(1);
    const [pageSize, setPageSize] = useState(ITEMS_PER_PAGE);
    const [channelCount, setChannelCount] = useState(ITEMS_PER_PAGE);

    const loadChannels = async (startIdx, pageSize) => {
        setLoading(true);
        try {
            const res = await API.get(`/api/channel/?p=${startIdx}&page_size=${pageSize}`);
            const { success, message, data } = res.data;
            if (success) {
                setChannels(data);
                if (data.length >= pageSize) {
                    setChannelCount((startIdx + 1) * pageSize + pageSize);
                } else {
                    setChannelCount(startIdx * pageSize + data.length);
                }
            } else {
                showError(message);
            }
        } catch (error) {
            showError(error.message);
        }
        setLoading(false);
    };

    const handlePageChange = page => {
        setActivePage(page);
        loadChannels(page - 1, pageSize);
    };

    const handlePageSizeChange = async (size) => {
        localStorage.setItem('page-size', size + '');
        setPageSize(size);
        setActivePage(1);
        await loadChannels(0, size);
    };

    useEffect(() => {
        const localPageSize = parseInt(localStorage.getItem('page-size')) || ITEMS_PER_PAGE;
        setPageSize(localPageSize);
        loadChannels(0, localPageSize);
    }, []);

    const renderStatus = (status) => {
        switch (status) {
            case 1:
                return <Tag size="large" color="green">已启用</Tag>;
            case 2:
                return <Tag size="large" color="yellow">已禁用</Tag>;
            case 3:
                return <Tag size="large" color="yellow">自动禁用</Tag>;
            default:
                return <Tag size="large" color="grey">未知状态</Tag>;
        }
    };

    const renderType = (type) => {
        const typeInfo = CHANNEL_OPTIONS.find(option => option.value === type) || 
                        { value: 0, text: '未知类型', color: 'grey' };
        return <Tag size="large" color={typeInfo.color}>{typeInfo.text}</Tag>;
    };

    const searchChannels = async () => {
        if (searchKeyword === '') {
            await loadChannels(0, pageSize);
            return;
        }
        setSearching(true);
        try {
            const res = await API.get(`/api/channel/search?keyword=${searchKeyword}`);
            const { success, message, data } = res.data;
            if (success) {
                setChannels(data);
                setChannelCount(data.length);
                setActivePage(1);
            } else {
                showError(message);
            }
        } catch (error) {
            showError(error.message);
        }
        setSearching(false);
    };

    const testChannel = async (channel) => {
        setTestingChannels(prev => new Set(prev).add(channel.id));
        
        try {
            const res = await API.get(`/api/channel/test/${channel.id}`);
            const { success, message, time } = res.data;
            
            setChannels(channels => channels.map(ch => {
                if (ch.id === channel.id) {
                    return {
                        ...ch,
                        response_time: time * 1000,
                        test_time: Date.now() / 1000,
                        last_test_error: success ? null : message
                    };
                }
                return ch;
            }));

            if (success) {
                showSuccess(`渠道 ${channel.name} 测试成功，耗时 ${time.toFixed(2)} 秒`);
            } else {
                showError(message);
            }
        } catch (error) {
            setChannels(channels => channels.map(ch => {
                if (ch.id === channel.id) {
                    return {
                        ...ch,
                        last_test_error: error.message
                    };
                }
                return ch;
            }));
            showError(error.message);
        } finally {
            setTestingChannels(prev => {
                const next = new Set(prev);
                next.delete(channel.id);
                return next;
            });
        }
    };

    const getLatestTime = (channel) => {
        const times = [
            channel.created_time || 0,
            channel.test_time || 0,
            channel.balance_updated_time || 0
        ];
        return Math.max(...times);
    };

    const renderTestButton = (channel) => {
        let buttonText = '测试';
        let buttonType = 'primary';

        if (testingChannels.has(channel.id)) {
            buttonText = '测试中';
        } else if (channel.last_test_error) {
            buttonText = '重新测试';
        }

        return (
            <div className="card-actions">
                <Button 
                    theme="borderless"
                    type={buttonType}
                    onClick={() => testChannel(channel)}
                    loading={testingChannels.has(channel.id)}
                    className="test-button"
                >
                    {buttonText}
                </Button>
            </div>
        );
    };

    return (
        <Layout style={{ background: 'var(--semi-color-bg-0)' }}>
            <Layout.Header style={{ 
                background: 'var(--semi-color-bg-1)', 
                padding: '24px', 
                borderBottom: '1px solid var(--semi-color-border)' 
            }}>
                <Typography.Title heading={3} style={{ margin: 0 }}>渠道看板</Typography.Title>
            </Layout.Header>
            <Layout.Content style={{ padding: '24px' }}>
                <div style={{ maxWidth: '1400px', margin: '0 auto' }}>
                    <Space style={{ 
                        marginBottom: '24px', 
                        width: '100%', 
                        justifyContent: 'space-between',
                        background: 'var(--semi-color-bg-2)',
                        padding: '16px',
                        borderRadius: '8px'
                    }}>
                        <Form layout="horizontal" style={{ width: '300px' }}>
                            <Form.Input
                                field="search_keyword"
                                label="搜索"
                                placeholder="ID，名称和密钥 ..."
                                value={searchKeyword}
                                loading={searching}
                                onChange={(v) => setSearchKeyword(v.trim())}
                                onEnterPress={searchChannels}
                                style={{ width: '100%' }}
                            />
                        </Form>
                        <Button 
                            icon={<IconRefresh />} 
                            onClick={() => loadChannels(activePage - 1, pageSize)}
                            theme="solid"
                            type="primary"
                        >
                            刷新
                        </Button>
                    </Space>

                    {loading ? (
                        <div style={{ 
                            display: 'flex', 
                            justifyContent: 'center', 
                            padding: '50px',
                            background: 'var(--semi-color-bg-2)',
                            borderRadius: '8px'
                        }}>
                            <Spin size="large" />
                        </div>
                    ) : (
                        <>
                            <div style={{ 
                                display: 'grid', 
                                gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))', 
                                gap: '24px',
                                marginBottom: '24px'
                            }}>
                                {channels.map(channel => (
                                    <Card
                                        key={channel.id}
                                        className="channel-card"
                                        style={{ 
                                            width: '100%',
                                            backgroundColor: channel.status !== 1 ? 'var(--semi-color-bg-2)' : 'var(--semi-color-bg-1)',
                                            borderRadius: '8px',
                                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                                            transition: 'transform 0.2s, box-shadow 0.2s',
                                            ':hover': {
                                                transform: 'translateY(-2px)',
                                                boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                                            }
                                        }}
                                        actions={[
                                            renderTestButton(channel)
                                        ]}
                                        header={
                                            <Meta
                                                title={
                                                    <div style={{ 
                                                        display: 'flex', 
                                                        justifyContent: 'space-between',
                                                        alignItems: 'flex-start',
                                                        width: '100%'
                                                    }}>
                                                        <div style={{ 
                                                            fontSize: '16px', 
                                                            fontWeight: 600,
                                                            marginRight: '8px',
                                                        }}>
                                                            {channel.name}
                                                        </div>
                                                        <div style={{
                                                            display: 'flex',
                                                            gap: '4px',
                                                            flexShrink: 0,
                                                            alignItems: 'center'
                                                        }}>
                                                            {renderStatus(channel.status)}
                                                            {!testingChannels.has(channel.id) && (
                                                                channel.test_time && !channel.last_test_error ? 
                                                                <Tag size="large" color="blue">测试成功</Tag> :
                                                                channel.last_test_error && 
                                                                <Tooltip content={channel.last_test_error}>
                                                                    <Tag size="large" color="red">测试失败</Tag>
                                                                </Tooltip>
                                                            )}
                                                            {testingChannels.has(channel.id) && 
                                                                <Tag size="large" color="blue">测试中...</Tag>
                                                            }
                                                            <Tag size="small" color="grey">ID: {channel.id}</Tag>
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        }
                                    >
                                        <Space vertical align="start" spacing="tight" style={{ width: '100%' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                <span>类型:</span>
                                                <span>{renderType(channel.type)}</span>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                <span>优先级:</span>
                                                <span>{channel.priority}</span>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                <span>响应时间:</span>
                                                <span>{channel.response_time ? `${(channel.response_time / 1000).toFixed(2)}秒` : '未测试'}</span>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                <span>更新时间:</span>
                                                <span>{timestamp2string(getLatestTime(channel))}</span>
                                            </div>
                                            {channel.last_test_error && (
                                                <div style={{ 
                                                    color: 'var(--semi-color-danger)',
                                                    background: 'var(--semi-color-danger-light-default)',
                                                    padding: '8px',
                                                    borderRadius: '4px',
                                                    width: '100%',
                                                    marginTop: '8px'
                                                }}>
                                                    <Typography.Text type="danger" strong>最近错误:</Typography.Text>
                                                    <div style={{ marginTop: '4px' }}>{channel.last_test_error}</div>
                                                </div>
                                            )}
                                        </Space>
                                    </Card>
                                ))}
                            </div>
                            <div style={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                marginTop: '24px',
                                background: 'var(--semi-color-bg-2)',
                                padding: '16px',
                                borderRadius: '8px'
                            }}>
                                <Pagination
                                    currentPage={activePage}
                                    pageSize={pageSize}
                                    total={channelCount}
                                    
                                    onPageChange={handlePageChange}
                                    showTotal={true}
                                    formatPageText={(page) => ''}
                                />
                            </div>
                        </>
                    )}
                </div>
            </Layout.Content>
        </Layout>
    );
};

export default ChannelDashBoard;
