import React, { useState, useEffect } from 'react';
import {Layout} from "@douyinfe/semi-ui";

const Modelleaderboard = () => {
  // 使用useState钩子来控制是否显示iframe
  const [showIframe, setShowIframe] = useState(false);

  useEffect(() => {
    // 设置为true以显示iframe
    setShowIframe(true);
  }, []);

  return (
    <Layout>
      <Layout.Header>
        {/*<h3>司南排行榜</h3>  标题内容 */}
      </Layout.Header>
      <Layout.Content>
        {showIframe && (
          <iframe
            // 替换为需要显示的URL
            // src="https://topai.ganjiuwanshi.com"
            src="https://www.ganjiuwanshi.com"
            title="External Content"
            style={{ width: '100%', height: '100vh', border: 'none' }}
          />
        )}
      </Layout.Content>
    </Layout>
  );
};

export default Modelleaderboard;
