import React, { useState, useEffect, useRef } from 'react';
import { Input, Select, Button, List, Card, message, Row, Col, Slider, Switch, Tooltip, Form, Tabs, Modal, InputNumber } from 'antd';
import { SendOutlined, DeleteOutlined, SwapOutlined, InfoCircleOutlined, CopyOutlined, CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { v4 as uuidv4 } from 'uuid';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

// 自定义代码块组件
const CodeBlock = ({ language, value }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(value);
      setCopied(true);
      message.success('代码已复制');
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      message.error('复制失败');
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <Button
        size="small"
        type="text"
        icon={copied ? <CheckOutlined /> : <CopyOutlined />}
        onClick={handleCopy}
        style={{
          position: 'absolute',
          right: 10,
          top: 10,
          zIndex: 1,
          color: copied ? '#52c41a' : '#fff',
          backgroundColor: 'rgba(0,0,0,0.2)',
        }}
      />
      <SyntaxHighlighter
        language={language || 'text'}
        style={vscDarkPlus}
        customStyle={{
          margin: 0,
          padding: '16px',
          borderRadius: '6px',
        }}
      >
        {value}
      </SyntaxHighlighter>
    </div>
  );
};

const Talkground = () => {
  const navigate = useNavigate();
  const [apiKey, setApiKey] = useState('');
  const [selectedModel, setSelectedModel] = useState('gpt-3.5-turbo');
  const [inputMessage, setInputMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({
    systemPrompt: 'You are chatgpt, a large language model trained. Follow the user\'s instructions carefully. Respond using markdown.',
    temperature: 0.8,
    maxTokens: 2000,
    topP: 0.8,
    stream: false,
    useSystemPrompt: true,
    isSimplified: true
  });

  // 模型列表
  const modelsSimplified = [
    "gpt-4o",
    "gpt-4o-mini",
    "gpt-3.5-turbo",
    "gpt-4",

    "aws-claude-3-5-haiku-20241022",
    "aws-claude-3-5-sonnet-20241022",
    "aws-claude-3-sonnet-20240229",
    "aws-claude-3-opus-20240229",

    "gemini-2.0-flash-exp",
    "gemini-1.5-flash",
    "gemini-1.5-pro",
    "gemini-1.0-pro",

    "moonshot-v1-8k",
    "moonshot-v1-32k",
    "moonshot-v1-128k",

    "glm-4-0520", "glm-4-air",

    "qwen-turbo",
    "qwen-plus",
    "qwen-max",
    "llama3.1-8b-instruct", "llama3.1-70b-instruct", "llama3.1-405b-instruct",
    "yi-large", "yi-large-rag", "yi-medium", "yi-large-turbo", "qwen-vl-plus", "qwen-vl-max",
    "flux-schnell", "flux-dev", "flux-merged",

    "Doubao-pro-128k",
    "Doubao-lite-128k",

    "deepseek-chat",

    "grok-2-latest",
  ];

  const models = [
    "gpt-4o", "gpt-4o-mini",
    "gpt-4o-mini-2024-07-18", "gpt-4o-2024-08-06",
    "o1-preview", "o1-preview-2024-09-12",
    "o1-mini", "o1-mini-2024-09-12",
    "gpt-3.5-turbo", "gpt-3.5-turbo-0301", "gpt-3.5-turbo-0613", "gpt-3.5-turbo-1106", "gpt-3.5-turbo-0125",
    "gpt-3.5-turbo-16k", "gpt-3.5-turbo-16k-0613",
    "gpt-3.5-turbo-instruct",
    "gpt-4", "gpt-4-0314", "gpt-4-0613", "gpt-4-1106-preview", "gpt-4-0125-preview",
    "gpt-4-32k", "gpt-4-32k-0314", "gpt-4-32k-0613",
    "gpt-4-turbo-preview", "gpt-4-turbo", "gpt-4-turbo-2024-04-09",
    "gpt-4-vision-preview",
    "text-embedding-ada-002", "text-embedding-3-small", "text-embedding-3-large",
    "text-curie-001", "text-babbage-001", "text-ada-001", "text-davinci-002", "text-davinci-003",
    "text-moderation-latest", "text-moderation-stable",
    "text-davinci-edit-001",
    "davinci-002", "babbage-002",
    "dall-e-2", "dall-e-3",
    "whisper-1",
    "tts-1", "tts-1-1106", "tts-1-hd", "tts-1-hd-1106",

    "claude-instant-1.2", "claude-2.0", "claude-2.1",
    "claude-3-haiku-20240307",
    "claude-3-sonnet-20240229",
    "claude-3-opus-20240229",
    "claude-3-5-sonnet-20240620",

    "aws-claude-instant-1.2", "aws-claude-2.0", "aws-claude-2.1",
    "aws-claude-3-haiku-20240307",
    "aws-claude-3-sonnet-20240229",
    "aws-claude-3-opus-20240229",
    "aws-claude-3-5-sonnet-20240620",
    "aws-claude-3-5-haiku-20241022",
    "aws-claude-3-5-sonnet-20241022",

    "gemini-2.0-flash-exp",
    "gemini-1.5-flash-latest",
    "gemini-1.5-flash",
    "gemini-1.5-flash-001",
    "gemini-1.5-flash-002",
    "gemini-1.5-flash-8b-exp-0924",
    "gemini-1.5-flash-8b-exp-0827",
    "gemini-1.5-flash-exp-0827",
    "gemini-1.5-pro-latest",
    "gemini-1.5-pro",
    "gemini-1.5-pro-001",
    "gemini-1.5-pro-002",
    "gemini-1.5-pro-exp-0827",
    "gemini-1.0-pro-latest",
    "gemini-1.0-pro",
    "gemini-1.0-pro-001",

    "moonshot-v1-8k",
    "moonshot-v1-32k",
    "moonshot-v1-128k",

    "embedding-2", "embedding-3",
    "glm-4", "glm-4v", "glm-3-turbo", "glm-4-0520", "glm-4-air", "glm-4-airx", "glm-4-flash",

    "qwen-max", "qwen-max-latest", "qwen-max-0919", "qwen-max-0428", "qwen-max-0403", "qwen-max-0107", "qwen-max-longcontext",
    "qwen-plus", "qwen-plus-latest", "qwen-plus-0919", "qwen-plus-0806", "qwen-plus-0723", "qwen-plus-0624", "qwen-plus-0206",
    "qwen-turbo", "qwen-turbo-latest", "qwen-turbo-0919", "qwen-turbo-0624", "qwen-turbo-0206",
    "qwen-coder-turbo", "qwen-coder-turbo-latest", "qwen-coder-turbo-0919",
    "qwen-long",
    "qwen-vl-max", "qwen-vl-max-0809", "qwen-vl-plus-0809", "qwen-vl-plus-0201", "qwen-vl-plus",
    "qwen-math-plus", "qwen-math-plus-latest", "qwen-math-plus-0919", "qwen-math-plus-0816", "qwen-math-turbo", "qwen-math-turbo-latest", "qwen-math-turbo-0919",
    "qwen-audio-turbo",
    "qwen2.5-72b-instruct", "qwen2.5-32b-instruct", "qwen2.5-14b-instruct", "qwen2.5-7b-instruct", "qwen2.5-3b-instruct", "qwen2.5-1.5b-instruct", "qwen2.5-0.5b-instruct",
    "qwen2-72b-instruct", "qwen2-57b-a14b-instruct", "qwen2-7b-instruct",
    "llama3.1-8b-instruct", "llama3.1-70b-instruct", "llama3.1-405b-instruct",
    "yi-large", "yi-large-rag", "yi-medium", "yi-large-turbo",
    "flux-schnell", "flux-dev", "flux-merged",
    "text-embedding-v1",

    "Doubao-pro-128k",
    "Doubao-pro-32k",
    "Doubao-pro-4k",
    "Doubao-lite-128k",
    "Doubao-lite-32k",
    "Doubao-lite-4k",
    "Doubao-embedding",

    "deepseek-chat",

    "grok-2",
    "grok-2-latest",
    "grok-2-1212",
    "grok-beta",
    "grok-vision-beta",
    "grok-2-vision-1212",
  ];

  // 对话管理状态
  const [conversations, setConversations] = useState([]);
  const [activeConversationId, setActiveConversationId] = useState(null);
  const [newConversationTitle, setNewConversationTitle] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  // 在组件顶部添加 ref
  const messagesEndRef = useRef(null);

  // 添加滚动到底部的函数
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // 从本地存储加载数据
  useEffect(() => {
    const savedConversations = localStorage.getItem('conversations');
    const savedApiKey = localStorage.getItem('apiKey');
    const savedSettings = localStorage.getItem('chatSettings');

    let initialConversations = [];

    if (savedConversations) {
      try {
        const parsedConversations = JSON.parse(savedConversations);
        if (parsedConversations && parsedConversations.length > 0) {
          initialConversations = parsedConversations;
        } else {
          initialConversations = [{
            id: uuidv4(),
            title: '新对话 1',
            messages: []
          }];
        }
      } catch (error) {
        initialConversations = [{
          id: uuidv4(),
          title: '新对话 1',
          messages: []
        }];
      }
    } else {
      initialConversations = [{
        id: uuidv4(),
        title: '新对话 1',
        messages: []
      }];
    }

    setConversations(initialConversations);
    setActiveConversationId(initialConversations[0].id);

    if (savedApiKey) {
      setApiKey(savedApiKey);
    }
    if (savedSettings) {
      setSettings(JSON.parse(savedSettings));
    }
  }, []);

  // 保存数据到本地存储
  useEffect(() => {
    if (conversations.length > 0) {
      localStorage.setItem('conversations', JSON.stringify(conversations));
    }
  }, [conversations]);

  useEffect(() => {
    localStorage.setItem('apiKey', apiKey);
  }, [apiKey]);

  useEffect(() => {
    localStorage.setItem('chatSettings', JSON.stringify(settings));
  }, [settings]);

  // 获取当前对话
  const getCurrentConversation = () => {
    return conversations.find(conv => conv.id === activeConversationId) || { messages: [] };
  };

  // 创建新对话
  const createNewConversation = () => {
    const defaultTitle = `对话框${conversations.length + 1}`;
    setNewConversationTitle(defaultTitle);
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    const newConversation = {
      id: uuidv4(),
      title: newConversationTitle || `新对话 ${conversations.length + 1}`,
      messages: []
    };
    setConversations([...conversations, newConversation]);
    setActiveConversationId(newConversation.id);
    setNewConversationTitle('');
    setIsModalVisible(false);
  };

  // 删除对话
  const deleteConversation = (conversationId) => {
    Modal.confirm({
      title: '确认删除',
      content: '确定要删除这个对话吗？',
      onOk: () => {
        const newConversations = conversations.filter(conv => conv.id !== conversationId);
        setConversations(newConversations);
        if (conversationId === activeConversationId) {
          setActiveConversationId(newConversations[0]?.id);
        }
      }
    });
  };

  // 更新对话消息
  const updateConversationMessages = (conversationId, newMessages) => {
    setConversations(prevConversations =>
      prevConversations.map(conv =>
        conv.id === conversationId
          ? { ...conv, messages: newMessages }
          : conv
      )
    );
  };

  const handleSend = async () => {
    if (!apiKey) {
      message.error('请输入API Key');
      return;
    }
    if (!inputMessage.trim()) {
      message.error('请输入消息');
      return;
    }

    setLoading(true);
    const currentConversation = getCurrentConversation();
    const userMessage = { role: 'user', content: inputMessage };
    let currentAssistantMessage = { role: 'assistant', content: '' };

    try {
      const messages = settings.useSystemPrompt
        ? [{ role: 'system', content: settings.systemPrompt }, ...currentConversation.messages, userMessage]
        : [...currentConversation.messages, userMessage];

      const response = await fetch('https://ganjiuwanshi.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify({
          model: selectedModel,
          messages: messages,
          temperature: settings.temperature,
          max_tokens: settings.maxTokens,
          top_p: settings.topP,
          stream: settings.stream
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error?.message || '请求失败');
      }

      // 更新用户消息
      const newMessages = [...currentConversation.messages, userMessage];
      updateConversationMessages(activeConversationId, newMessages);

      if (settings.stream) {
        // 添加初始的AI消息
        updateConversationMessages(activeConversationId, [...newMessages, currentAssistantMessage]);

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value);
          const lines = chunk.split('\n');

          for (const line of lines) {
            if (line.trim() === '') continue;
            if (line.trim() === 'data: [DONE]') continue;

            try {
              const jsonStr = line.replace(/^data: /, '');
              const json = JSON.parse(jsonStr);

              if (json.choices[0]?.delta?.content) {
                currentAssistantMessage.content += json.choices[0].delta.content;
                updateConversationMessages(
                  activeConversationId,
                  [...newMessages, { ...currentAssistantMessage }]
                );
              }
            } catch (e) {
              console.error('解析流式数据出错:', e);
            }
          }
        }
      } else {
        const data = await response.json();
        const assistantMessage = data.choices[0].message;
        updateConversationMessages(
          activeConversationId,
          [...newMessages, assistantMessage]
        );
      }

      setInputMessage('');
    } catch (error) {
      message.error(error.message);
      // 发生错误时移除最后添加的消息
      updateConversationMessages(
        activeConversationId,
        currentConversation.messages
      );
    } finally {
      setLoading(false);
    }
  };

  const clearHistory = () => {
    Modal.confirm({
      title: '确认清除',
      content: '确定要清除当前对话的所有消息吗？',
      onOk: () => {
        updateConversationMessages(activeConversationId, []);
        message.success('聊天记录已清除');
      }
    });
  };

  // 获取当前对话的消息
  const currentMessages = getCurrentConversation().messages;

  useEffect(() => {
    scrollToBottom();
  }, [currentMessages]);

  return (
    <div style={{ padding: '20px' }}>
      <Row justify="space-between" align="middle" style={{ marginBottom: '20px' }}>
        <Col>
          <h2>AI 聊天</h2>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<SwapOutlined />}
            onClick={() => navigate('/playground')}
          >
            切换到代码生成
          </Button>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={16}>
          <Card
            style={{ marginBottom: '20px' }}
            extra={
              <Button type="primary" icon={<PlusOutlined />} onClick={createNewConversation}>
                新对话
              </Button>
            }
          >
            <Tabs
              activeKey={activeConversationId}
              onChange={setActiveConversationId}
              type="editable-card"
              onEdit={(targetKey, action) => {
                if (action === 'add') {
                  createNewConversation();
                } else if (action === 'remove') {
                  deleteConversation(targetKey);
                }
              }}
            >
              {conversations.map(conversation => (
                <TabPane
                  tab={conversation.title}
                  key={conversation.id}
                >
                  <div style={{ height: 'calc(100vh - 300px)', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ flex: 1, overflowY: 'auto', marginBottom: '20px', border: '1px solid #d9d9d9', borderRadius: '4px', padding: '10px' }}>
                      <List
                        dataSource={conversation.messages}
                        renderItem={(item) => (
                          <List.Item style={{
                            justifyContent: item.role === 'user' ? 'flex-end' : 'flex-start'
                          }}>
                            <Card style={{
                              maxWidth: '80%',
                              backgroundColor: item.role === 'user' ? '#e6f7ff' : '#f0f0f0'
                            }}>
                              <ReactMarkdown
                                components={{
                                  code: ({ node, inline, className, children, ...props }) => {
                                    const match = /language-(\w+)/.exec(className || '');
                                    return !inline ? (
                                      <CodeBlock
                                        language={match ? match[1] : ''}
                                        value={String(children).replace(/\n$/, '')}
                                        {...props}
                                      />
                                    ) : (
                                      <code className={className} {...props}>
                                        {children}
                                      </code>
                                    );
                                  }
                                }}
                              >
                                {item.content}
                              </ReactMarkdown>
                            </Card>
                          </List.Item>
                        )}
                      />
                      <div ref={messagesEndRef} />
                    </div>

                    <div style={{ display: 'flex', gap: '10px' }}>
                      <TextArea
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        placeholder="今天你有什么想问的吗？请在此输入消息..."
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        style={{ flex: 1 }}
                        onPressEnter={(e) => {
                          if (!e.shiftKey) {
                            e.preventDefault();
                            handleSend();
                          }
                        }}
                      />
                      <Button
                        type="primary"
                        icon={<SendOutlined />}
                        onClick={handleSend}
                        loading={loading}
                      >
                        发送
                      </Button>
                      <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={clearHistory}
                      >
                        清除历史
                      </Button>
                    </div>
                  </div>
                </TabPane>
              ))}
            </Tabs>
          </Card>
        </Col>

        <Col span={8} style={{ position: 'sticky', top: 20 }}>
          <Card
            title="设置"
            bordered={true}
            style={{
              maxHeight: 'calc(100vh - 200px)',
              overflowY: 'auto',
              overflowX: 'hidden',
              paddingRight: '10px'
            }}
          >
            <div style={{ marginBottom: '20px' }}>
              <Input.Password
                placeholder="请输入API Key"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
              />
            </div>

            <Form.Item label={
              <span>
                简化模型 <Tooltip title="简化显示的模型，关闭则显示全部可选模型"><InfoCircleOutlined /></Tooltip>
              </span>
            }>
              <Switch
                checked={settings.isSimplified}
                onChange={checked => setSettings({ ...settings, isSimplified: checked })}
              />

            </Form.Item>

            <Form.Item label={
              <span>
                模型 <Tooltip title="用于生成回复��模型。"><InfoCircleOutlined /></Tooltip>
              </span>
            }>
              <Select
                style={{ width: '100%' }}
                value={selectedModel}
                onChange={setSelectedModel}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                }
                placeholder="搜索或选择模型"
                listHeight={200}
              >
                {settings.isSimplified ?
                  modelsSimplified.map(model => (
                    <Option key={model} value={model}>{model}</Option>
                  ))
                  :
                  models.map(model => (
                    <Option key={model} value={model}>{model}</Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item label={
              <span>
                System Prompt <Tooltip title="系统提示词，用于设定AI助手的角色和行为"><InfoCircleOutlined /></Tooltip>
              </span>
            }>
              <Switch
                checked={settings.useSystemPrompt}
                onChange={(checked) => setSettings({ ...settings, useSystemPrompt: checked })}
                style={{ marginBottom: '10px' }}
              />
              {settings.useSystemPrompt && (
                <TextArea
                  value={settings.systemPrompt}
                  onChange={(e) => setSettings({ ...settings, systemPrompt: e.target.value })}
                  autoSize={{ minRows: 2, maxRows: 4 }}
                />
              )}
            </Form.Item>

            <Form.Item label={
              <span>
                Temperature <Tooltip title="控制输出的随机性，值越高输出越随机"><InfoCircleOutlined /></Tooltip>
              </span>
            }>
              <Row>
                <Col span={18}>
                  <Slider
                    min={0}
                    max={2}
                    step={0.1}
                    value={settings.temperature}
                    onChange={(value) => setSettings({ ...settings, temperature: value })}
                  />
                </Col>
                <Col span={4} offset={2}>
                  <InputNumber
                    min={0}
                    max={2}
                    step={0.1}
                    value={settings.temperature}
                    onChange={(value) => setSettings({ ...settings, temperature: value })}
                    style={{ width: '100%' }}
                  />
                </Col>
              </Row>
            </Form.Item>

            <Form.Item label={
              <span>
                Top P <Tooltip title="控制输出的多样性"><InfoCircleOutlined /></Tooltip>
              </span>
            }>
              <Row>
                <Col span={18}>
                  <Slider
                    min={0}
                    max={1}
                    step={0.1}
                    value={settings.topP}
                    onChange={(value) => setSettings({ ...settings, topP: value })}
                  />
                </Col>
                <Col span={4} offset={2}>
                  <InputNumber
                    min={0}
                    max={1}
                    step={0.1}
                    value={settings.topP}
                    onChange={(value) => setSettings({ ...settings, topP: value })}
                    style={{ width: '100%' }}
                  />
                </Col>
              </Row>
            </Form.Item>

            <Form.Item label={
              <span>
                Max Tokens <Tooltip title="控制输出的最大长度"><InfoCircleOutlined /></Tooltip>
              </span>
            }>
              <Row>
                <Col span={18}>
                  <Slider
                    min={100}
                    max={4000}
                    step={100}
                    value={settings.maxTokens}
                    onChange={(value) => setSettings({ ...settings, maxTokens: value })}
                  />
                </Col>
                <Col span={4} offset={2}>
                  <InputNumber
                    min={100}
                    max={4000}
                    step={100}
                    value={settings.maxTokens}
                    onChange={(value) => setSettings({ ...settings, maxTokens: value })}
                    style={{ width: '100%' }}
                  />
                </Col>
              </Row>
            </Form.Item>

            <Form.Item label={
              <span>
                Stream <Tooltip title="是否使用流式输出"><InfoCircleOutlined /></Tooltip>
              </span>
            }>
              <Switch
                checked={settings.stream}
                onChange={(checked) => setSettings({ ...settings, stream: checked })}
              />
            </Form.Item>
            
            <div style={{ 
              backgroundColor: '#f5f5f5', 
              padding: '12px', 
              borderRadius: '6px',
              marginBottom: '20px'
            }}>
              <h4 style={{ marginBottom: '8px' }}>使用说明：</h4>
              <ul style={{ paddingLeft: '20px', margin: 0 }}>
                <li>输入 API Key 开始对话</li>
                <li>Shift + Enter 换行，Enter 发送</li>
                <li>开启 Stream 获得实时回复</li>
                <li>调高 Temperature 获得更有创意的回复</li>
              </ul>
              
              <h4 style={{ marginTop: '16px', marginBottom: '8px' }}>注意事项：</h4>
              <ul style={{ paddingLeft: '20px', margin: 0, color: '#666' }}>
                <li>对话历史保存在本地，清除浏览器数据会丢失</li>
                <li>API Key 请妥善保管</li>
                <li>长文本请适当调高 Max Tokens</li>
              </ul>
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        title="创建新对话"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={() => {
          setIsModalVisible(false);
          setNewConversationTitle('');
        }}
      >
        <Input
          placeholder="输入对话标题"
          value={newConversationTitle}
          onChange={(e) => setNewConversationTitle(e.target.value)}
          autoFocus
        />
      </Modal>
    </div>
  );
};

export default Talkground;
