import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Form, Modal } from '@douyinfe/semi-ui';
import { API, showError, showSuccess } from '../helpers';

const ModelPriceSetting = () => {
    const [models, setModels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editingModel, setEditingModel] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [searchText, setSearchText] = useState('');

    // 从后端获取当前的模型价格配置
    const getModelPrices = async () => {
        setLoading(true);
        try {
            const res = await API.get('/api/option/');
            const { success, message, data } = res.data;
            if (success) {
                const modelRatio = data.find(item => item.key === 'ModelRatio');
                if (modelRatio) {
                    const modelPrices = JSON.parse(modelRatio.value);
                    const formattedModels = Object.entries(modelPrices).map(([name, price]) => ({
                        name,
                        price: typeof price === 'number' ? price : parseFloat(price)
                    }));
                    setModels(formattedModels);
                }
            } else {
                showError(message);
            }
        } catch (error) {
            showError(error.message);
        }
        setLoading(false);
    };

    // 保存修改后的价格
    const saveModelPrices = async (updatedModels = models) => {
        const modelPrices = {};
        updatedModels.forEach(model => {
            modelPrices[model.name] = parseFloat(model.price);
        });

        try {
            const res = await API.put('/api/option/', {
                key: 'ModelRatio',
                value: JSON.stringify(modelPrices, null, 2)
            });
            const { success, message } = res.data;
            if (success) {
                showSuccess('价格设置已更新');
                await getModelPrices(); // 刷新数据
            } else {
                showError(message);
            }
        } catch (error) {
            showError(error.message);
        }
    };

    // 编辑单个模型价格
    const handleEdit = (record) => {
        setEditingModel(record);
        setModalVisible(true);
    };

    // 更新价格
    const handleUpdate = async (values) => {
        try {
            // 将输入的实际价格转换回倍率
            const actualPrice = parseFloat(values.price);
            if (isNaN(actualPrice) || actualPrice < 0) {
                showError('请输入有效的价格');
                return;
            }

            // 将实际价格转换回倍率 (实际价格 / 0.002)
            const newPrice = actualPrice / 0.002;

            const newModels = models.map(model => 
                model.name === editingModel.name 
                    ? { ...model, price: newPrice }
                    : model
            );
            
            await saveModelPrices(newModels);
            setModalVisible(false);
        } catch (error) {
            showError('更新失败：' + error.message);
        }
    };

    // 搜索过滤
    const filteredModels = models.filter(model => 
        model.name.toLowerCase().includes(searchText.toLowerCase())
    );

    useEffect(() => {
        getModelPrices();
    }, []);

    // 判断价格单位（USD或RMB）
    const getPriceUnit = (modelName) => {
        if (!modelName) return 'RMB';
        
        if (modelName.startsWith('gpt-') || 
            modelName.startsWith('claude-') || 
            modelName.startsWith('mistral-') ||
            modelName.startsWith('gemini-')) {
            return 'USD';
        }
        return 'RMB';
    };

    // 格式化价格显示
    const formatPrice = (price, modelName) => {
        if (!price) return '0.0000';
        const unit = getPriceUnit(modelName);
        const priceValue = price * 0.002; // 转换为实际价格（1 === $0.002）
        return `${priceValue.toFixed(4)} ${unit}/1K tokens`;
    };

    const columns = [
        {
            title: '模型名称',
            dataIndex: 'name',
            width: '50%',
            sorter: (a, b) => a.name.localeCompare(b.name)
        },
        {
            title: '价格',
            dataIndex: 'price',
            width: '30%',
            sorter: (a, b) => a.price - b.price,
            render: (price, record) => formatPrice(price, record.name)
        },
        {
            title: '操作',
            dataIndex: 'operate',
            render: (text, record) => (
                <Button onClick={() => handleEdit(record)}>
                    编辑
                </Button>
            ),
        },
    ];

    return (
        <div>
            <div style={{ marginBottom: 16 }}>
                <Input
                    style={{ width: 200, marginRight: 16 }}
                    placeholder="搜索模型名称"
                    value={searchText}
                    onChange={value => setSearchText(value)}
                />
                <Button onClick={() => getModelPrices()}>刷新</Button>
            </div>

            <Table
                columns={columns}
                dataSource={filteredModels}
                loading={loading}
                pagination={{
                    pageSize: 20,
                }}
                rowKey="name"
            />

            <Modal
                title="编辑模型价格"
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
            >
                {editingModel && (
                    <Form onSubmit={handleUpdate}>
                        <Form.Input
                            field="modelName"
                            label="模型名称"
                            disabled
                            initValue={editingModel.name}
                        />
                        <Form.Input
                            field="price"
                            label={`价格 (${getPriceUnit(editingModel.name)}/1K tokens)`}
                            initValue={(editingModel.price * 0.002).toFixed(4)}
                            rules={[
                                { required: true, message: '请输入价格' },
                                {
                                    validator: (rule, value) => {
                                        const num = parseFloat(value);
                                        return !isNaN(num) && num >= 0;
                                    },
                                    message: '请输入有效的非负数字'
                                }
                            ]}
                        />
                        <div style={{ marginTop: 16 }}>
                            <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
                                保存
                            </Button>
                            <Button onClick={() => setModalVisible(false)}>
                                取消
                            </Button>
                        </div>
                    </Form>
                )}
            </Modal>
        </div>
    );
};

export default ModelPriceSetting; 